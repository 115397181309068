import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tasks--question"
export default class extends Controller {
  static targets = ['defaultHiddenAnswer', 'select']
  static classes = ['hidden']
  static values = {
    containerId: String,
    position: String,
    selectedOptionIndex: Number,
    initialSelectedOptionIndex: Number,
    toggleSiblingsByPositionsAttr: { type: String, default: 'data-show-questions' },
    visible: { type: Boolean, default: true }
  }

  async selectedOptionIndexValueChanged(value, _previousValue) {
    if (!this.hasSelectTarget) return

    const hidePositions = [...new Set(
      Array.from(this.selectTarget.options).map(option => {
        if (option.index == value) return []
        return option.attributes[this.toggleSiblingsByPositionsAttrValue]?.value?.split(' ') || []
      }).flat()
    )]

    const showPositions =
      this.selectTarget.options[value]
        ?.attributes[this.toggleSiblingsByPositionsAttrValue]
        ?.value?.split(' ') || []

    await this.#toggleSiblingsByPositions(hidePositions, false)
    await this.#toggleSiblingsByPositions(showPositions, true)
    this.selectTarget.value = this.selectTarget.options[value].getAttribute('value')
  }

  async visibleValueChanged(value, previousValue) {
    if (previousValue == undefined) return

    if (value) {
      this.element.classList.remove(this.hiddenClass)

      if (this.hasSelectTarget) {
        await Promise.resolve(this.selectedOptionIndexValue = this.initialSelectedOptionIndexValue)
      }
    } else {
      this.element.classList.add(this.hiddenClass)

      if (this.hasSelectTarget && this.hasDefaultHiddenAnswerTarget) {
        await Promise.resolve(this.selectedOptionIndexValue = this.defaultHiddenAnswerTarget.index)
      }
    }
  }

  connect() {
    if (this.hasSelectTarget) {
      if (this.hasDefaultHiddenAnswerTarget && this.selectTarget.selectedIndex == this.defaultHiddenAnswerTarget.index) {
        this.initialSelectedOptionIndexValue = 0
      } else {
        this.initialSelectedOptionIndexValue = this.selectTarget.selectedIndex
      }

      this.selected()
    }
  }

  async selected() {
    await Promise.resolve(this.selectedOptionIndexValue = this.selectTarget.selectedIndex)
  }

  async #toggleSiblingsByPositions(positions, visible) {
    await Promise.all(
      this.application.controllers.filter(instance => {
        return (
          instance.identifier == this.identifier &&
            instance.containerIdValue == this.containerIdValue &&
            positions.includes(instance.positionValue)
        )
      }).map(instance => Promise.resolve(instance.visibleValue = visible))
    )
  }
}

import { Controller } from "@hotwired/stimulus"
import {patch} from "@rails/request.js";

// Connects to data-controller="autoraters--update"
export default class extends Controller {
  static values = { graderUrl: String, raterUrl: String }
  static targets = ['autoGrader', 'question', 'raterId', 'raterName']

  changeRater() {
    const formData = new FormData()
    formData.append('auto_rater[rater_attributes][first_name]', this.raterNameTarget.value)
    formData.append('auto_rater[rater_attributes][id]', this.raterIdTarget.value)
    this.#update(this.raterUrlValue, formData)
  }

  changeGrader(e) {
    const formData = new FormData()
    formData.append('auto_grader_id', this.autoGraderTarget.value)
    formData.append('question_id', this.questionTarget.value)

    this.#update(this.graderUrlValue, formData)
  }

  #update(url, formData) {
    patch(url, { responseKind: 'turbo-stream', body: formData })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText)
      })
      .catch(() => alert('There was a problem saving your changes.'))
  }
}

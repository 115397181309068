import Component from '../../store/lib/component.js';

const CALCULATOR_SELECTOR = '#experiment-length-calculator-wrapper';

export default class Calculator extends Component {
  constructor(props = {}) {
    super(props);

    props.store.events.subscribe('fetchSamplesData', () => this.fetchSamplesData());
    props.store.events.subscribe('runCalculator', () => { if (!this.store.state.isDelta) { this.runCalculator() } });
    props.store.events.subscribe('updateStateFromCalculatorRun', (payload) => this.updateStateFromCalculatorRun(payload));
  }

  fetchSamplesData() {
    this.clearAll();

    if (this.validatesState()) {
      const elem = $(CALCULATOR_SELECTOR);
      const data = elem.find(`:input`).serializeArray();
      data.push({ name: 'experiment_calculator[timestamp]', value: (new Date().getTime()) });

      const requestUrl = elem.data('perform-url');
      $.post(requestUrl, data, 'json').done((response) => {
        // Call action to update the state with new data
        this.store.events.publish('updateStateFromCalculatorRun', response );

        if (this.store.state.isDelta) {
          this.store.events.publish('updateResults');
        } else {
          // Publish event so our SamplesData component update it's values
          this.store.events.publish('refreshSamplesData');
          this.store.events.publish('showSamplesData');

          // Print the chart
          this.store.events.publish('renderChart');
          this.runCalculator();
        }
      }).fail((response) => {
        this.store.events.publish('processErrors', response);
      });
    } else {
      this.store.events.publish('resetResults');
    }
  }

  clearAll() {
    this.store.events.publish('refreshSamplesData', { forcedValue: ' ' });

    this.store.events.publish('destroyChart');
    this.store.events.publish('hideSamplesData');
  }

  updateStateFromCalculatorRun(payload) {
    if (this.store.state.isDelta) {
      // To avoid race conditions we timestamp our calls and return it from the server
      if (payload.timestamp > this.store.state.timestamp) {
        this.store.commit('timestamp', payload.timestamp);
        this.store.commit('calculatedDays', payload.calculatedDays);
      }
    } else {
      this.store.commit('numerator', payload.numerator);
      this.store.commit('denominator', payload.denominator);
      this.store.commit('rate', payload.rate);
      this.store.commit('dailySamples', payload.dailySamples);
      this.store.commit('deltaMethodFactor', payload.deltaMethodFactor);
      this.store.commit('chartData', JSON.parse(payload.chartData));
    }
  }

  runCalculator() {
    const calculatedSamples = this.calculateSamples(this.store.state.rate, this.store.state.expectedLift);
    const calculatedDays = this.calculateDays(calculatedSamples);

    this.store.commit('calculatedSamples', calculatedSamples);
    this.store.commit('calculatedDays', calculatedDays);

    this.store.events.publish('updateResults');
  }

  calculateSamples(rate, lift) {
    if (this.anyInvalidNumber(rate, lift)) { return null; }

    // Formula provided by the Data Science team
    const computedLift = lift / 100;
    const samples = 2 * rate * (1 - rate) * ((this.store.state.zAlpha + this.store.state.zBeta) ** 2) / ((rate * computedLift) ** 2);

    return Math.ceil(samples);
  }

  calculateDays(calculatedSamples) {
    const { state: { trafficPerTreatment, impactPercentage, dailySamples, deltaMethodFactor } } = this.store;

    if (this.anyInvalidNumber(calculatedSamples, trafficPerTreatment, impactPercentage, dailySamples)) {
      return null;
    }

    const dailySamplesPerTreatment = (dailySamples * trafficPerTreatment / 100 * impactPercentage / 100);

    return (calculatedSamples / dailySamplesPerTreatment) * deltaMethodFactor;
  }

  validatesState() {
    return !this.store.state.initializationMode
      && this.store.state.measure != null
      && (this.store.state.dateType != '' || (this.store.state.dateStart != null && this.store.state.dateEnd != null));
  }

  anyInvalidNumber(...args) {
    for (const value of args) {
      if (!isFinite(value) || value <= 0) {
        return true;
      }
    }
    return false;
  }
}

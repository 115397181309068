class DefaultState {

  constructor(props = {}) {
    this.DEFAULT_DATE_TYPE = 'past_six_months';
    this.DEFAULT_TEST_TYPE = 1.959963985; // Two sided
    this.DEFAULT_EXPECTED_LIFT = 0.5;
    this.DEFAULT_TRAFFIC_PER_TREATMENT = 5;
    this.DEFAULT_IMPACT_PERCENTAGE = 100;
    this.Z_BETA = 0.842; // pre-calculated, power of 0.8

    if(props.hasOwnProperty('element')) {
      this.element = props.element;
    }
  }

  loadState = () => {
    return {
      testType:     this.DEFAULT_TEST_TYPE,
      dateType:     this.DEFAULT_DATE_TYPE,
      dataMeasures: this.element ? this.element.data('data-measures') : null,
      dateStart:    null,
      dateEnd:      null,
      measure:      null,
      measures:     [],
      dataSource:   null,
      filters:      {},

      // Values that will be retrieved from the server
      numerator:         null,
      denominator:       null,
      rate:              null,
      dailySamples:      null,
      chartData:         null,
      deltaMethodFactor: 1.0,

      // Values that will be calculated or input
      expectedLift:        this.DEFAULT_EXPECTED_LIFT,
      zBeta:               this.Z_BETA,
      zAlpha:              parseFloat(this.DEFAULT_TEST_TYPE),
      calculatedSamples:   null,
      calculatedDays:      null,
      trafficPerTreatment: this.DEFAULT_TRAFFIC_PER_TREATMENT,
      impactPercentage:    this.DEFAULT_IMPACT_PERCENTAGE,
      sequential:          false,
      isDelta:             false,
      timestamp:           0
    };
  };

}

export default DefaultState;

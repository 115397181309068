Turbo.StreamActions.addClass = function () {
  const klass = $(this).attr('class')
  const removeAfter = Number($(this).attr('remove_after'))

  this.targetElements.forEach((el) => {
    $(el).addClass(klass)

    if (removeAfter > 0) {
      setTimeout(() => $(el).removeClass(klass), removeAfter)
    }
  })
}

Turbo.StreamActions.removeClass = function () {
  const klass = $(this).attr('class')

  this.targetElements.forEach((el) => {
    $(el).removeClass(klass)
  })
}

Turbo.StreamActions.closeModal = function () {
  this.targetElements.forEach((el) => {
    $(el).modal('hide')
  })
}

Turbo.StreamActions.flashConfirmationIcon = function () {
  $(this.targetElements).flashConfirmationIcon()
}

Turbo.StreamActions.triggerEvent = function () {
  this.targetElements.forEach((el) => {
    $(el).trigger(this.getAttribute('event'))
  })
}

Turbo.StreamActions.updateDocumentTitle = function() {
  document.title = this.getAttribute('title');
}

Turbo.StreamActions.setAttribute = function () {
  const attribute = $(this).attr('attribute'),
    value = $(this).attr('value')

  this.targetElements.forEach((el) => {
    $(el).attr(attribute, value)
  })
}

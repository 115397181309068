import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-show"
export default class extends Controller {
  static targets = ["input", "output"]

  connect() {
    this.toggle()
  }

  toggle() {
    this.outputTargets.forEach(output => {
      if (this.inputTarget.value === output.dataset.conditionalShowIfValue) {
        output.hidden = false;
      } else {
        output.hidden = true;
      };
    })
  }
}

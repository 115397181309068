import { reporter } from "mtkit/reporter"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea-logger"
export default class extends Controller {
  static targets = ["textArea"]
  static values = {
    keystrokeCount: { type: Number, default: 0 },
    context: Object
  }

  connect() {
    this.element.addEventListener('input', this.#onInputEvent.bind(this))
  }

  disconnect() {
    this.#reportEvent('keypress', { inputType: 'insertText', length: this.keystrokeCountValue })
  }

  #reportEvent(eventType, data) {
    reporter.recordCustomEvent(eventType, { ...data, ...this.contextValue })
  }

  async #onInputEvent(event) {
    const { inputType, data } = event

    if (inputType == 'insertText') {
      this.keystrokeCountValue++
    } else {
      this.#reportEvent('paste', { inputType, length: (data || event.dataTransfer?.getData('Text'))?.length })
    }
  }
}

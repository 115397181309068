import { merge } from "lodash";

const defaults = {
  language: {
    lengthMenu: "_MENU_ rows per page",
    search: "_INPUT_",
    searchPlaceholder: "Find in this table…"
  },
  bSortCellsTop: false
};

const extend = (builder, options) => new DataTableOptionsBuilder(merge({}, builder.options, options))

class DataTableOptionsBuilder {
  constructor(o = defaults) {
    this.options = o;
  }

  default = (o) => extend(this, o);

  dom = (dom) => extend(this, { dom })

  columnDef = (c) => extend(this, { columnDefs: [...(this.options.columnDefs || []), c] })

  order = (o) => extend(this, { order: [...(this.options.order || []), ...(o || [])] });

  emptyTable = (message) => message ? extend(this, { language: { emptyTable: message } }) : this

  call = () => merge({}, this.options);
}

export default DataTableOptionsBuilder;
